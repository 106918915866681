import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query FileQuery {
        file(relativePath: { eq: "logo/logo.png" }) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <header>
        <div>
          <Link to="/">
            <Img fixed={data.file.childImageSharp.fixed} />
          </Link>
        </div>
      </header>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
