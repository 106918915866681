/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import Email from "./Email"
import "./layout.css"
import { Container } from "reactstrap"
import Menu from "./Menu"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            shorttitle
            phone
            phonelink
            fax
            street
            city
            zipcode
          }
        }
      }
    `}
    render={data => (
      <Container fluid className="">
        <CookieConsent
          location="top"
          buttonText="OK"
          style={{ backgroundColor: "#DE0424" }}
          buttonStyle={{ background: "white", borderRadius: "3px" }}
        >
          Willkommen! Diese Webseite und darin eingebundene externe Dienste
          verwenden Cookies. Weitere Informationen zum Datenschutz, externen
          Diensten und Ihren Rechten finden Sie in unserer{" "}
          <u>
            <Link className="text-reset" to="/impressum#datenschutz">
              Datenschutzerklärung
            </Link>
          </u>
          .
        </CookieConsent>
        <Menu>
          <Header siteTitle={data.site.siteMetadata.title} />
        </Menu>
        <div>
          <main>{children}</main>
          <footer className="text-center small py-2">
            © {new Date().getFullYear()} {data.site.siteMetadata.shorttitle}{" "}
            &middot; {data.site.siteMetadata.street} &middot;{" "}
            {data.site.siteMetadata.zipcode} {data.site.siteMetadata.city}{" "}
            &middot; Telefon:&nbsp;
            <a
              className="text-dark"
              href={data.site.siteMetadata.phonelink}
              dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.phone }}
            />{" "}
            &middot; Fax:&nbsp;
            <span
              dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.fax }}
            />{" "}
            &middot; Email:&nbsp;
            <Email />
          </footer>
        </div>
      </Container>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
